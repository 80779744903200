import { capitalize, computed, Ref, ref, watch } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { api, fetchInfosForTitles, updateUserOpts, ViewFilter } from 'boot/axios'

import { AsyncData } from 'components/models'
import { useAuthUserStore } from 'stores/auth-user-store'
import { CountriesInner, EntityType, ExchangesInner, ListsInner, TitleInformation } from '@stockpulse/typescript-axios'
import { routeRequiresAuthorization } from 'src/router/routes'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import * as Sentry from '@sentry/vue'
import { CaptureContext } from '@sentry/types'
import { CancellationPolicy } from 'src/services/AxiosRequestManager'

export interface InventoryTitle {
  n: string,
  type: string,
  id: number,
  icon: string
}

export interface TitleInfoCollection {
  [key: number]: AsyncData<TitleInformation>
}

interface UserFacingLoadingState {
  label: string,
  loading: boolean
}

export type ExtendedCountriesInner = CountriesInner & { icon?: string }

export interface InventorySector {
  name: string,
  type: string,
  id: number,
  icon: string
}

export type ExchangeData = ExchangesInner & { icon?: string }

export interface KeyEvent {
  id: number,
  name: string,
  icon: string
}

export interface Source {
  source: string,
  lang: string,
  type: string,
  source_type: string,
  icon?: string
}

export interface AssetEntityType {
  label: string,
  value: string
}

export interface MessageSourceType {
  type: string,
  class: string,
  icon: string | null
}

export interface UiPreferences {
  markets: string[],
  titleTypes: string[]
}

export interface Markets {
  US: boolean,
  CA: boolean,
  CN: boolean,
  IN: boolean,
  EU: boolean,
  REST_AS: boolean
}

export type MarketTypes = keyof Markets;

export interface TitleTypes {
  company: boolean,
  index: boolean,
  forex: boolean,
  crypto_currency: boolean,
  raw_material: boolean,
  fund: boolean
}

export interface UiPreferencesGUI {
  markets: Markets
  titleTypes: TitleTypes,
  titleTypesList: string[],
  countryUnCodes: string[],
  countryIsoCodes: string[]
}

export interface UserOpts {
  uiPreferences?: UiPreferences
}

const STORE_CURRENT_VERSION = 1

const SUSPICIOUS_LOADING_TIMEOUT_MS = 15_000

export const useInventoryStore = defineStore('inventoryStore', () => {
  // "You must call useI18n at top of the setup"
  const { t } = useI18n()
  const router = useRouter()
  const authUserStore = useAuthUserStore()
  const { hasValidSubscription } = storeToRefs(authUserStore)

  const isStoreRestored = ref(false)
  const isInitialized = ref(false)
  const hasError = ref(false)
  const hasSuspiciousLoadingTime = ref(false)
  const loadingTimeoutId: Ref<undefined | number> = ref()

  const keyEvents: Ref<AsyncData<{ [key: number]: KeyEvent }>> = ref({ loading: true, value: {} })
  const titles: Ref<AsyncData<{ [key: number]: InventoryTitle }>> = ref({ loading: true, value: {} })
  const sectors: Ref<AsyncData<{ [key: number]: InventorySector }>> = ref({ loading: true, value: {} })
  const countries: Ref<AsyncData<ExtendedCountriesInner[]>> = ref({ loading: true, value: [] })
  const exchanges: Ref<AsyncData<ExchangeData[]>> = ref({ loading: true, value: [] })
  const lists: Ref<AsyncData<ListsInner[]>> = ref({ loading: true, value: [] })
  const sources: Ref<AsyncData<Source[]>> = ref({ loading: true, value: [] })
  const messageSourceTypes: Ref<AsyncData<MessageSourceType[]>> = ref({ loading: true, value: [] })
  const userOpts: Ref<AsyncData<UserOpts>> = ref({ loading: true, value: {} })

  const entityTypes: Ref<AssetEntityType[]> = ref([
    { label: 'Company', value: EntityType.Company },
    { label: 'Crypto Currency', value: EntityType.CryptoCurrency },
    { label: 'Derivative', value: EntityType.Derivative },
    { label: 'Forex', value: EntityType.Forex },
    { label: 'Index', value: EntityType.Index },
    { label: 'Raw Material', value: EntityType.RawMaterial },
    { label: 'Fund', value: EntityType.Fund }
  ])

  const titlesInfo: Ref<TitleInfoCollection> = ref({})

  // trigger login check immediate, to open socket after auth store hydration
  watch(() => [router.currentRoute.value.name, hasValidSubscription, isStoreRestored.value], () => {
    if (isInitialized.value ||
      !routeRequiresAuthorization(String(router.currentRoute.value.name)) ||
      !hasValidSubscription ||
      !isStoreRestored.value
    ) {
      return
    }
    loadData()
  }, { immediate: true })

  const userFacingLoadingStates = computed<UserFacingLoadingState[]>(() => {
    return [
      { label: t('InitialLoadingComponent.assetsLabel'), loading: titles.value.loading },
      { label: t('InitialLoadingComponent.exchangesLabel'), loading: exchanges.value.loading && lists.value.loading },
      { label: t('InitialLoadingComponent.countriesLabel'), loading: countries.value.loading },
      { label: t('InitialLoadingComponent.sourcesLabel'), loading: sources.value.loading },
      { label: t('InitialLoadingComponent.keyEventsLabel'), loading: keyEvents.value.loading },
      { label: t('InitialLoadingComponent.sectorsLabel'), loading: sectors.value.loading }
    ]
  })

  const uiPreferencesGUI = computed<UiPreferencesGUI>(() => {
    const euCountries = countries.value.value
      ?.filter(country => country.continent_code === 'EU')
      ?.map(country => country.iso) ??
      []
    const restOfAsia = countries.value.value
      ?.filter(country => country.continent_code === 'AS' && !['CN', 'IN'].includes(country.iso ?? ''))
      ?.map(country => country.iso) ??
      []

    const countryUnCodes = userOpts.value.value?.uiPreferences?.markets?.map(countryIso => {
      return getCountryByIso(countryIso)?.un
    }).filter(countryIso => countryIso !== undefined) as string[] ?? []

    const countryIsoCodes = userOpts.value.value?.uiPreferences?.markets?.map(countryIso => {
      return getCountryByIso(countryIso)?.iso
    }).filter(countryIso => countryIso !== undefined) as string[] ?? []

    return {
      markets: {
        US: userOpts.value.value?.uiPreferences?.markets?.includes('US') ?? false,
        CA: userOpts.value.value?.uiPreferences?.markets?.includes('CA') ?? false,
        CN: userOpts.value.value?.uiPreferences?.markets?.includes('CN') ?? false,
        IN: userOpts.value.value?.uiPreferences?.markets?.includes('IN') ?? false,
        EU: euCountries.every(euCountry =>
          userOpts.value.value?.uiPreferences?.markets?.includes(euCountry ?? '')) ?? false,
        REST_AS: restOfAsia.every(asCountry =>
          userOpts.value.value?.uiPreferences?.markets?.includes(asCountry ?? '')) ?? false
      },
      titleTypes: {
        company: userOpts.value.value?.uiPreferences?.titleTypes?.includes('company') ?? false,
        forex: userOpts.value.value?.uiPreferences?.titleTypes?.includes('forex') ?? false,
        index: userOpts.value.value?.uiPreferences?.titleTypes?.includes('index') ?? false,
        crypto_currency: userOpts.value.value?.uiPreferences?.titleTypes?.includes('crypto_currency') ?? false,
        raw_material: userOpts.value.value?.uiPreferences?.titleTypes?.includes('raw_material') ?? false,
        fund: userOpts.value.value?.uiPreferences?.titleTypes?.includes('fund') ?? false
      },
      countryUnCodes: countryUnCodes ?? [],
      countryIsoCodes: countryIsoCodes ?? [],
      titleTypesList: userOpts.value.value?.uiPreferences?.titleTypes ?? []
    }
  })

  const transformGUIPreferencesToUiPreferences = (preferences: UiPreferencesGUI): UiPreferences => {
    const markets: string[] = []

    const euCountries: string[] = countries.value.value
      ?.filter(country => country.continent_code === 'EU' && country.iso)
      ?.map(country => country.iso ?? '') ??
      []
    const restOfAsia: string[] = countries.value.value
      ?.filter(country => country.continent_code === 'AS' && country.iso && !['CN', 'IN'].includes(country.iso ?? ''))
      ?.map(country => country.iso ?? '') ??
      []

    Object.keys(preferences.markets).forEach(mapKey => {
      if (!preferences.markets[mapKey as keyof Markets]) {
        return
      }
      switch (mapKey) {
      case 'EU':
        markets.push(...euCountries)
        break
      case 'REST_AS':
        markets.push(...restOfAsia)
        break
      default:
        markets.push(mapKey)
      }
    })

    const titleTypes: string[] = []
    Object.keys(preferences.titleTypes).forEach(titleType => {
      if (!preferences.titleTypes[titleType as keyof TitleTypes]) {
        return
      }
      titleTypes.push(titleType)
    })

    return {
      markets,
      titleTypes
    }
  }

  async function loadData (): Promise<void> {
    isInitialized.value = false
    hasError.value = false

    if (loadingTimeoutId.value) {
      window.clearTimeout(loadingTimeoutId.value)
    }
    loadingTimeoutId.value = window.setTimeout(() => {
      hasSuspiciousLoadingTime.value = true
    }, SUSPICIOUS_LOADING_TIMEOUT_MS)

    try {
      const requests: Promise<void>[] = [
        loadTitles(),
        loadUserOpts()
      ]

      if (!sectors.value.value || !Object.keys(sectors.value.value).length) {
        requests.push(loadSectors())
      } else {
        // We manually set loading to false cause of a problem
        // with the local storage where the loading flag gets not stored correctly!
        sectors.value.loading = false
      }

      if (!lists.value.value || !lists.value.value.length) {
        requests.push(loadLists())
      } else {
        lists.value.loading = false
      }

      if (!keyEvents.value.value || !Object.keys(keyEvents.value.value).length) {
        requests.push(loadKeyEvents())
      } else {
        keyEvents.value.loading = false
      }

      if (!sources.value.value || !sources.value.value.length) {
        requests.push(loadSources())
      } else {
        sources.value.loading = false
      }

      if (!countries.value.value || !countries.value.value.length) {
        requests.push(loadCountries())
      } else {
        countries.value.loading = false
      }

      if (!exchanges.value.value || !exchanges.value.value.length) {
        requests.push(loadExchanges())
      } else {
        exchanges.value.loading = false
      }

      if (!messageSourceTypes.value.value || !messageSourceTypes.value.value.length) {
        requests.push(loadMessageSourceTypes())
      } else {
        messageSourceTypes.value.loading = false
      }

      await Promise.all(requests)

      hasError.value = false
      isInitialized.value = true
      hasSuspiciousLoadingTime.value = false
    } catch (error) {
      const context: CaptureContext = {
        user: {
          subscription_type: authUserStore.user.subscription_type,
          username: authUserStore.user.username
        },
        level: 'fatal'
      }
      Sentry.captureException(error, context)
      hasError.value = true
      isInitialized.value = false
    } finally {
      window.clearTimeout(loadingTimeoutId.value)
    }
  }

  async function loadKeyEvents (): Promise<void> {
    const fetchedKeyEvents = await fetchKeyEvents()
    keyEvents.value = {
      loading: false,
      value: fetchedKeyEvents
    }
  }

  async function fetchKeyEvents (): Promise<{ [key: number]: KeyEvent }> {
    const response = await api.get('/v6/key_events_v2?&add_icon=true')
    const fetchedKeyEvents: Array<{ key_event_id: number; name: string, icon: string }> = response.data.reduce(
      (carry: Array<{ key_event_id: number, name: string, icon: string }>,
        a: { key_events: Array<{ key_event_id: number, name: string, icon: string }> }
      ) =>
        carry.concat(a.key_events), [] as Array<{ key_event_id: number, name: string, icon: string }>
    )
    const keyEventCollection: { [key: number]: KeyEvent } = {}
    fetchedKeyEvents.forEach(keyEvent => {
      keyEventCollection[keyEvent.key_event_id] = {
        name: keyEvent.name,
        icon: keyEvent.icon,
        id: keyEvent.key_event_id
      }
    })
    return keyEventCollection
  }

  async function updateUserOptions (uiPreferences: UiPreferences) : Promise<void> {
    const newOpts = await updateUserOpts(userOpts.value, uiPreferences)
    if (newOpts) {
      userOpts.value = {
        loading: false,
        value: newOpts
      }
    }
  }

  async function loadUserOpts (): Promise<void> {
    const response = await api.get(
      '/v6/userdata/opts',
      { cancellationPolicy: CancellationPolicy.OnLogout }
    )
    userOpts.value = {
      loading: false,
      value: response.data as UserOpts
    }
  }

  async function loadExchanges (): Promise<void> {
    const fetchedExchanges = await api.get('/v6/exchanges?&add_icon=true')
    exchanges.value = {
      loading: false,
      value: fetchedExchanges.data.map((exchange : ExchangeData) => {
        return {
          exchange_id: exchange.exchange_id,
          name: exchange.name,
          mic: exchange.mic,
          country: exchange.country,
          icon: exchange.icon
        }
      })
    }
  }

  async function loadCountries (): Promise<void> {
    const fetchedCountries = await api.get('/v6/countries?&add_icon=true')
    countries.value = {
      loading: false,
      value: fetchedCountries.data
    }
  }

  async function loadSources (): Promise<void> {
    const fetchedSources = await api.get('/v6/sources?add_icon=true')
    sources.value = {
      loading: false,
      value: fetchedSources.data
    }
  }

  async function loadMessageSourceTypes (): Promise<void> {
    const fetchedMessageSourceTypes = await api.get('/v6/source_types?add_icon=true')
    messageSourceTypes.value = {
      loading: false,
      value: fetchedMessageSourceTypes.data
    }
  }

  async function loadTitles (): Promise<void> {
    const fetchedTitles = await fetchTitles()
    const titlesObject: { [key: number]: InventoryTitle } = {}
    fetchedTitles.forEach(title => {
      titlesObject[title.id] = title
    })

    titles.value = {
      loading: false,
      value: titlesObject
    }
  }

  async function fetchTitles (): Promise<InventoryTitle[]> {
    const response = await api.get('/v6/inventory?add_icon=true&primaryFieldsOnly=true')
    return response.data
  }

  async function loadSectors (): Promise<void> {
    const fetchedSectors = await fetchSectors()
    const sectorsObject: { [key: number]: InventorySector } = {}
    fetchedSectors.forEach(sector => {
      sectorsObject[sector.id] = sector
    })
    sectors.value = {
      loading: false,
      value: sectorsObject
    }
  }

  async function fetchSectors (): Promise<InventorySector[]> {
    const response = await api.get('/v6/sectors?add_icon=true')
    return response.data
  }

  async function loadLists (): Promise<void> {
    const fetchedLists = await fetchLists()
    lists.value = {
      loading: false,
      value: fetchedLists
    }
  }

  async function fetchLists (): Promise<ListsInner[]> {
    const response = await api.get('/v6/lists?add_icon=true')
    return response.data
  }

  function translateKeyEvent (keyEventId: number): string {
    const keyEventName = keyEvents.value.value ? keyEvents.value.value[keyEventId]?.name : undefined

    if (!keyEventName) {
      return ''
    }
    const translationKey = 'MessageStatistics.keyEvent.' + keyEventName
    const translation = t(translationKey)
    return translation !== translationKey ? translation : keyEventName
  }

  function getKeyEventIconById (eventId: number): string | undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId]?.icon : undefined
  }

  function getKeyEventById (eventId: number): KeyEvent | undefined {
    return keyEvents.value.value ? keyEvents.value.value[eventId] : undefined
  }

  function getSectorIconById (sectorId: number): string | undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.icon : undefined
  }

  function getSectorNameById (sectorId: number): string | undefined {
    return sectors.value.value ? sectors.value.value[sectorId]?.name : undefined
  }

  function getSectorById (sectorId: number): InventorySector | undefined {
    return sectors.value.value ? sectors.value.value[sectorId] : undefined
  }

  function getListById (listId: number): ListsInner | undefined {
    return lists.value.value?.find(list => list.id === listId)
  }

  function getTitleNameById (titleId: number): string {
    return titles.value.value?.[titleId]?.n || ''
  }

  function getTitleById (titleId: number): InventoryTitle | undefined {
    return titles.value.value?.[titleId]
  }

  function getTitleIconById (titleId: number): string | undefined {
    return titles.value.value?.[titleId]?.icon
  }

  function getTitlesById (titleIds: number[]): InventoryTitle[] {
    return titleIds.reduce((accumulatedTitles, titleId) => {
      const titleInfo = titles.value.value?.[titleId]
      if (titleInfo) {
        accumulatedTitles.push(titleInfo)
      }
      return accumulatedTitles
    }, [] as InventoryTitle[])
  }

  function getExchangeByName (exchangeName: string): ExchangeData | undefined {
    return exchanges.value.value?.find(exchange => exchange.name?.toUpperCase() === exchangeName.toUpperCase())
  }

  function getExchangeById (id: number): ExchangeData | undefined {
    return exchanges.value.value?.find(exchange => exchange.exchange_id === id)
  }

  function getSourceByName (name: string): Source | undefined {
    return sources.value.value?.find(source => source.source === name)
  }

  function getSourceIconByName (name: string, fallbackIcon = 'spi-globe'): string {
    return sources.value.value?.find(source => source.source === name)?.icon || fallbackIcon
  }

  function getMessageSourceTypeIconByName (name: string, fallbackIcon = 'spi-message-lines'): string {
    return messageSourceTypes.value.value?.find(sourceType => sourceType.type === name)?.icon || fallbackIcon
  }

  function getCountryByName (countryName: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.name === countryName)
  }

  function getCountryByUn (un: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.un === un)
  }

  function getCountryByIso (iso: string): ExtendedCountriesInner | undefined {
    return countries.value.value?.find(country => country.iso === iso)
  }

  function getCountryIconByUn (un: string): string | undefined {
    const iso = countries.value.value?.find(country => country.un === un)?.iso
    if (!iso) {
      return 'spi-globe'
    }
    return 'spf-flag-' + iso.toLowerCase()
  }

  function getEntityTypeByValue (value: string): AssetEntityType | undefined {
    return entityTypes.value.find(entityType => entityType.value === value)
  }

  function createNameFromTitleFilter (titleFilter: ViewFilter): string {
    const elements: (string | undefined)[] = []
    elements.push(...titleFilter.country.map((x: string) => getCountryByUn(x)?.name))
    elements.push(...titleFilter.exchange.map((x: number) => getExchangeById(x)?.name))
    // TODO regions
    // elements.push(...titleFilter.region.map((x: string) => x));
    elements.push(...titleFilter.sector.map((x: number) => getSectorById(x)?.name))
    elements.push(...titleFilter.list.map((x: number) => getListById(x)?.n))
    elements.push(...titleFilter.asset.map((x: number) => getTitleById(x)?.n))
    elements.push(...titleFilter.keyEvent.map((x: number) => getKeyEventById(x)?.name))
    elements.push(...titleFilter.type.map((x: string) =>
      x.replace('_', ' ')
        .split(' ')
        .map(word => capitalize(word))
        .join(' ')))
    const titleFilterName = elements.filter(x => x !== undefined).join(', ')
    return titleFilterName.length > 0 ? titleFilterName : t('TitleFilter.allAssets')
  }

  async function loadTitleInfo (titleId: number, cancellationPolicy?: CancellationPolicy): Promise<void> {
    await loadTitleInfos([titleId], cancellationPolicy)
  }

  async function loadTitleInfos (titleIds: number[], cancellationPolicy?: CancellationPolicy): Promise<void> {
    if (titleIds.every(titleId => titlesInfo.value[titleId]?.value !== undefined)) {
      return
    }

    for (const titleId of titleIds) {
      titlesInfo.value[titleId] = { loading: true }
    }

    const titleInfos = await fetchInfosForTitles(titleIds, cancellationPolicy)

    // Iterate over titleIds to set loading = false on missing title infos as well
    const newTitleInfos: TitleInfoCollection = {}
    for (const titleId of titleIds) {
      const titleInfo = titleInfos.find(titleInfo => titleInfo.id === titleId)

      if (titleInfo === undefined) {
        newTitleInfos[titleId] = { loading: false }
        continue
      }

      if (titleInfo.type === 'index') {
        newTitleInfos[titleId] = {
          loading: false,
          value: {
            ...titleInfo,
            currency: 'PTS'
          }
        }
        continue
      }

      newTitleInfos[titleId] = { loading: false, value: titleInfo }
    }

    // Update state variable only once
    titlesInfo.value = {
      ...titlesInfo.value,
      ...newTitleInfos
    }
  }

  return {
    isInitialized,
    hasError,
    hasSuspiciousLoadingTime,
    titles,
    loadData,
    getSectorIconById,
    getSectorNameById,
    getSectorById,
    translateKeyEvent,
    getKeyEventIconById,
    getKeyEventById,
    getTitleNameById,
    getTitleById,
    getTitlesById,
    getTitleIconById,
    getExchangeByName,
    getExchangeById,
    getCountryByName,
    getCountryByUn,
    getCountryIconByUn,
    getSourceIconByName,
    getSourceByName,
    getMessageSourceTypeIconByName,
    getEntityTypeByValue,
    entityTypes,
    sectors,
    exchanges,
    countries,
    keyEvents,
    lists,
    getListById,
    createNameFromTitleFilter,
    titlesInfo,
    userFacingLoadingStates,
    userOpts,
    uiPreferencesGUI,
    updateUserOptions,
    transformGUIPreferencesToUiPreferences,
    loadTitleInfo,
    loadTitleInfos,
    messageSourceTypes,
    sources,
    isStoreRestored
  }
}, {
  persist: {
    storage: localStorage,
    debug: true,
    paths: ['sectors', 'lists', 'keyEvents', 'sources', 'countries', 'exchanges', 'messageSourceTypes']
  },
  resetCache: {
    version: STORE_CURRENT_VERSION,
    expirySeconds: 24 * 3600
  }

})
